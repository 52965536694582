<div class="help-center-container">
	<div class="title-container">
		<h1 class="help-center-title">Help Center</h1>
	</div>
	<div class="help-grid">
		@for (columnId of ['column-1', 'column-2', 'column-3', 'column-4']; track columnId) {
			<mi-help-column
				[columnId]="columnId"
				[sections]="getSectionsForColumn(columnId)"
				(linkClicked)="openPdfPage($event)"></mi-help-column>
		}
	</div>
</div>
