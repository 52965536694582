import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { InviteContributorsModalComponent } from '@components/modals/invite-contributors-modal/invite-contributors-modal.component';
import { InviteOwnersModalComponent } from '@components/modals/invite-owners-modal/invite-owners-modal.component';
import { AppSecurityUser } from '@core/services/app-security/app-security.service';
import { CerbosRole, RoleNamesMap } from '@core/services/cerbos/cerbos.policies';
import {
	IfAllowContributorsInvitesDirective,
	IfAllowOwnersInvitesDirective
} from '@core/services/cerbos/resources/quote/quote-resource.directive';
import { ConfigService } from '@core/services/config/config.service';
import { UserOwnershipInviteParams } from '@interfaces/quote.interface';
import { RoleOption } from '@interfaces/roles.interface';
import { JblToastService } from '@jbl-pip/core';
import { JblIconModule } from '@jbl/foundation';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreferencesService, UserPreferencesType } from '@services/preferences.service';
import { QuotesModalService } from '@services/quotes-modal.service';
import { QuotesStateService } from '@stores/quotes.state.service';
import { catchError, filter, map, merge, of } from 'rxjs';

export const OWNER_INVITE_TOAST = {
	INVITE_SUCCESS: {
		body: 'Invitation sent successfully via email.'
	},
	INVITE__FAIL: {
		body: 'Something went wrong. Please try again.'
	}
};

export const CONTRIBUTOR_INVITE_TOAST = {
	INVITE_SUCCESS: {
		body: 'Invitation sent successfully via email.'
	},
	INVITE__FAIL: {
		body: 'Something went wrong. Please try again.'
	}
};

@Component({
	selector: 'mi-nav-sub-header',
	standalone: true,
	templateUrl: './nav-sub-header.component.html',
	styleUrl: './nav-sub-header.component.scss',
	imports: [IfAllowOwnersInvitesDirective, IfAllowContributorsInvitesDirective, CommonModule, JblIconModule, NgbDropdownModule]
})
export class NavSubHeaderComponent {
	private readonly modalService = inject(NgbModal);
	readonly modalsService = inject(QuotesModalService);
	readonly quotesState = inject(QuotesStateService);
	readonly configService = inject(ConfigService);
	readonly toastService = inject(JblToastService);
	readonly router = inject(Router);
	readonly route = inject(ActivatedRoute);
	readonly roleNamesMap = RoleNamesMap;
	private preferencesService = inject(PreferencesService);

	isRoleDropdownOpen = false;

	toggleRoleDropdown() {
		this.isRoleDropdownOpen = !this.isRoleDropdownOpen;
	}

	currentRole = computed(() => {
		const user = this.configService.currentUser();

		if (!user || !user.role) {
			return { label: 'Unknown role', value: null };
		}

		return {
			label: user.role in this.roleNamesMap ? this.roleNamesMap[user.role] : user.role,
			value: user.role
		};
	});

	availableRoles = computed(() => {
		const roles = this.configService.getAvailableRoles();
		const currentRoleValue = this.currentRole().value;

		return roles.filter(role => role !== currentRoleValue).map(role => ({ label: this.roleNamesMap[role], value: role }));
	});

	isClosed = computed(() => {
		const currentQuote = this.quotesState.currentQuote();
		const status = currentQuote ? currentQuote.status : 'Closed';

		return status === 'Closed';
	});

	showInviteButtons = toSignal(
		merge(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			this.route.data.pipe(map(() => (this.route.snapshot as any)._routerState.url)),
			this.router.events.pipe(
				filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
				map((e: NavigationEnd) => e.urlAfterRedirects)
			)
		).pipe(map((url: string) => /^\/quotes\/Q\d+$/.test(url)))
	);

	// ikurc TODO: Rework this method to use switchMap, reogranize structure
	openInviteOwnersModal(): void {
		const users = this.configService.appSecurityUsersWithLocalUpdates();
		const modalRef = this.modalService.open(InviteOwnersModalComponent, { centered: true });

		modalRef.componentInstance.usersList = users.filter((user: AppSecurityUser) => {
			const isAlreadyOwner = user.ownership.owns.includes(this.quotesState.state.currentQuoteId());
			const isAlreadyContributor = user.ownership.contributes.includes(this.quotesState.state.currentQuoteId());

			return !isAlreadyOwner && !isAlreadyContributor;
		});

		modalRef.closed.pipe(filter(Boolean)).subscribe((usersData: UserOwnershipInviteParams[]) => {
			const userIds = usersData.map((user: UserOwnershipInviteParams) => user.userId);
			const quoteId = this.quotesState.state.currentQuoteId();

			this.quotesState
				.inviteOwners(usersData)
				.pipe(
					catchError(() => {
						this.toastService.danger(OWNER_INVITE_TOAST.INVITE__FAIL);

						return of(null);
					})
				)
				.subscribe(data => {
					if (data) {
						this.configService.updateOwnershipLocally(quoteId, userIds, 'owns');
						this.toastService.success(OWNER_INVITE_TOAST.INVITE_SUCCESS);
					}
				});
		});
	}

	// ikurc TODO: Rework this method to use switchMap, reogranize structure
	openInviteContributorsModal(): void {
		const users = this.configService.appSecurityUsersWithLocalUpdates();
		const modalRef = this.modalService.open(InviteContributorsModalComponent, { centered: true });

		modalRef.componentInstance.usersList = users.filter((user: AppSecurityUser) => {
			const isAlreadyOwner = user.ownership.owns.includes(this.quotesState.state.currentQuoteId());
			const isAlreadyContributor = user.ownership.contributes.includes(this.quotesState.state.currentQuoteId());

			return !isAlreadyOwner && !isAlreadyContributor;
		});

		modalRef.closed.pipe(filter(Boolean)).subscribe((usersData: UserOwnershipInviteParams[]) => {
			const userIds = usersData.map((user: UserOwnershipInviteParams) => user.userId);
			const quoteId = this.quotesState.state.currentQuoteId();

			this.quotesState
				.inviteContributors(usersData)
				.pipe(
					catchError(() => {
						this.toastService.danger(CONTRIBUTOR_INVITE_TOAST.INVITE__FAIL);

						return of(null);
					})
				)
				.subscribe(data => {
					if (data) {
						this.configService.updateOwnershipLocally(quoteId, userIds, 'contributes');
						this.toastService.success(CONTRIBUTOR_INVITE_TOAST.INVITE_SUCCESS);
					}
				});
		});
	}

	switchRole(roleOption: RoleOption) {
		this.modalsService.openChangeRoleModal(roleOption).closed.subscribe((role: CerbosRole) => {
			this.preferencesService
				.setUserPreference('active-role', JSON.stringify({ selectedRole: role }), UserPreferencesType.Roles)
				.subscribe({
					next: () => window.location.reload(),
					error: () =>
						this.toastService.danger({
							header: 'Error',
							body: 'Role switch failed. Please, try again'
						})
				});
		});
	}
}
