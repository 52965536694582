import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { MiRoutes } from '@core/configs/routes';
import { DrawingsModuleActions } from '@core/services/cerbos/cerbos.policies';
import { DrawingPermissionsService } from '@core/services/cerbos/resources/drawing/drawing-resource.service';
import { Observable, of, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DrawingModuleGuardService {
	private router = inject(Router);
	private drawingPermissionsService = inject(DrawingPermissionsService);

	public canActivate(): Observable<boolean | UrlTree> {
		const allowed = this.drawingPermissionsService.checkPermission(DrawingsModuleActions.Access);

		return of(allowed).pipe(
			tap(allowed => {
				if (!allowed) {
					this.router.navigateByUrl(MiRoutes.noPermissions.url);
				}
			})
		);
	}
}

export const DrawingModuleGuard: CanActivateFn = (): Observable<boolean | UrlTree> => inject(DrawingModuleGuardService).canActivate();
