import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { MiRoutes } from '@core/configs/routes';
import { QuoteModuleActions } from '@core/services/cerbos/cerbos.policies';
import { QuotePermissionsService } from '@core/services/cerbos/resources/quote/quote-resource.service';
import { Observable, of, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class QuoteModuleGuardService {
	private router = inject(Router);
	private quotePermissionsService = inject(QuotePermissionsService);

	public canActivate(): Observable<boolean | UrlTree> {
		const allowed = this.quotePermissionsService.checkPermission(QuoteModuleActions.Access);

		return of(allowed).pipe(
			tap(allowed => {
				if (!allowed) {
					this.router.navigateByUrl(MiRoutes.noPermissions.url);
				}
			})
		);
	}
}

export const QuoteModuleGuard: CanActivateFn = (): Observable<boolean | UrlTree> => inject(QuoteModuleGuardService).canActivate();
