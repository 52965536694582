import { Injectable } from '@angular/core';
import { JblBearerTokenInterceptor } from '@jbl-pip/core-state';

@Injectable({
	providedIn: 'root'
})
export class BearerTokenInterceptor extends JblBearerTokenInterceptor {
	private readonly s3BuketPrefix = 's3-jbl'; // since we download files from s3 by pre-signed url, we should skip bearer auth

	protected whitelist: string[] = [this.s3BuketPrefix];
}
