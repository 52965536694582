import { Component, Input, output } from '@angular/core';
import { HelpSection } from '../help-center.constants';

@Component({
	selector: 'mi-help-column',
	standalone: true,
	imports: [],
	templateUrl: './help-column.component.html',
	styleUrl: './help-column.component.scss'
})
export class HelpColumnComponent {
	@Input() columnId!: string;
	@Input() sections: HelpSection[] = [];

	linkClicked = output<number>();

	openPdfPage(page: number) {
		this.linkClicked.emit(page);
	}
}
