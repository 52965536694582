import { Routes } from '@angular/router';
import { HelpCenterComponent } from '@components/help-center/help-center.component';
import { MiRoutes } from '@core/configs/routes';
import { AdminModuleGuard } from '@core/guards/admin-module.quard';
import { AuthGuard } from '@core/guards/auth.guard';
import { DrawingModuleGuard } from '@core/guards/drawing-module.guard';
import { QuoteModuleGuard } from '@core/guards/quote-module.guard';
import { SignoutGuard } from '@core/guards/signout.guard';
import { JblNotFoundComponent, JblNotPermittedComponent } from '@jbl-pip/core';
import { MainLayoutComponent } from './shared/components/app-layout/main-layout.component';

export const routes: Routes = [
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', redirectTo: MiRoutes.quotes.url, pathMatch: 'full' },
			{ path: 'dashboard', redirectTo: MiRoutes.quotes.url, pathMatch: 'full' },
			{
				path: MiRoutes.quotes.path,
				canActivate: [QuoteModuleGuard],
				loadChildren: () => import('./modules/quotes/quotes.routes').then(routes => routes.QuotesRoutes)
			},
			{
				path: MiRoutes.drawings.path,
				canActivate: [DrawingModuleGuard],
				loadChildren: () => import('./modules/drawings/drawings.routes').then(routes => routes.DrawingsRoutes)
			},
			{
				path: MiRoutes.admin.path,
				canActivate: [AdminModuleGuard],
				loadChildren: () => import('./modules/admin/admin.routes').then(routes => routes.AdminRoutes)
			},
			{
				path: MiRoutes.helpCenter.path,
				component: HelpCenterComponent
			}
		]
	},
	{
		path: MiRoutes.login.path,
		loadChildren: () => import('./modules/login/login.module').then(module => module.LoginModule),
		canActivate: [SignoutGuard]
	},
	{
		path: MiRoutes.noPermissions.path,
		component: JblNotPermittedComponent
	},
	{
		path: '**',
		component: JblNotFoundComponent
	}
];
