import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { MiRoutes } from '@core/configs/routes';
import { UserState } from '@core/services/user-state/user.state';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SignoutGuardService {
	private router = inject(Router);
	private userState = inject(UserState);

	public canActivate(): Observable<boolean | UrlTree> {
		return this.userState.userData$.pipe(
			map(({ isSignedIn }) => isSignedIn),
			tap(isSigned => {
				if (isSigned) {
					void this.router.navigate([MiRoutes.dashboard.url]);
				}
			}),
			map(isSigned => !isSigned)
		);
	}
}

export const SignoutGuard: CanActivateFn = (): Observable<boolean | UrlTree> => inject(SignoutGuardService).canActivate();
